/**
 * @file It contains scripts for not-found.
 */
export default {
  name: "NotFound",
  data() {
    return {
    }
  },
  methods: {
  },
  computed: {
  },
  watch: {
  }
}
